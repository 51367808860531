.h1{
    margin-left: 35px;
}
.Filters{
    width: 130px;
    padding: 15px 0;
    background: #3E3E3E;
    border-left: 1px solid #EAEAEA;
    font-size: 14px;
    color: white;
    cursor: pointer;
    text-align: center;
}
.FActive{
    background: white;
    color: #3E3E3E;
}
.MainBox{
    width: 95%;
    margin: 0 auto;
    box-shadow: 0px 10px 6px 5px rgba(0, 0, 0, 0.14);
}
.darkBtn{
    font-size: 14px;
    padding: 4px 12px;
    color: white;
    background: #3E3E3E;
    border-radius: 5px;
    margin-left: 35px;
    cursor: pointer;
}
.iaBox{
    display: inline-block;
    padding: 4px 28px;
    background: #FFFFFF;
    border-radius: 100px;
}
.HeadTable{
    background: rgba(222, 222, 222, 0.82);
    border-top: 1px solid rgba(115, 127, 210, 0.25);
}